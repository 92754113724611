import { render, staticRenderFns } from "./loading-cover.html?vue&type=template&id=1dd36240&scoped=true&"
import script from "./loading-cover.ts?vue&type=script&lang=ts&"
export * from "./loading-cover.ts?vue&type=script&lang=ts&"
import style0 from "./loading-cover.scss?vue&type=style&index=0&id=1dd36240&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dd36240",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/EmbeddedClient/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1dd36240')) {
      api.createRecord('1dd36240', component.options)
    } else {
      api.reload('1dd36240', component.options)
    }
    module.hot.accept("./loading-cover.html?vue&type=template&id=1dd36240&scoped=true&", function () {
      api.rerender('1dd36240', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/parts/plugin/loading-cover/loading-cover.vue"
export default component.exports