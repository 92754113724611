var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "w-100 h-100 d-flex justify-content-center align-items-center content"
    },
    [
      _c(
        "div",
        {
          staticClass: "w-75 d-flex justify-content-center align-items-center"
        },
        [
          _c("font-awesome-icon", {
            staticClass: "mr-4 my-0 h2",
            attrs: { icon: ["fas", "exclamation-triangle"] }
          }),
          _c("div", [
            _vm._v(
              "配線略図が見つからないか非公開になっているため、表示できません。"
            )
          ])
        ],
        1
      ),
      _c("div", { staticClass: "link" }, [
        _vm._v("Generated by "),
        _c("a", { attrs: { href: _vm.pageUrl, target: "_blank" } }, [
          _vm._v("配線略図エディタ")
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }