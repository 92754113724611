import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import sleep from '@/lib/sleep';
import ComponentBase from '@/components/component-base';

Component.registerHooks([
  'beforeRouteUpdate',
  'beforeRouteLeave'
]);

@Component
export default class PageBase extends ComponentBase {

  protected get loadingMessage(): string {
    return '';
  }

  protected init(): Promise<any>[] {
    return [Promise.resolve()
      .then(() => Promise.all(this.pageInit()))];
  }
  
  protected pageInit(): Promise<any>[] {
    return [];
  }

  protected isChanged(): boolean {
    return false;
  }
}
