import * as joint from 'jointjs';

export class ExtendedGraph extends joint.dia.Graph {

  private levelPapers: joint.dia.Paper[] = [];
  public addLevelPaper(paper: joint.dia.Paper) {
    this.levelPapers.push(paper);
  }

  // #region Overrides
  public addCell(cell: joint.dia.Cell | joint.dia.Cell[], opt?: { [key: string]: any }): this {
    const thisobj = super.addCell(cell, opt);

    if (Array.isArray(cell)) {
      cell.forEach(e => LevelUtil.addLevelClass(e, e.attributes.z, ...this.levelPapers));
    } else {
      LevelUtil.addLevelClass(cell, cell.attributes.z, ...this.levelPapers);
    }
    return thisobj;
  }

  public addCells(cells: joint.dia.Cell[], opt?: { [key: string]: any }): this {
    const thisobj = super.addCells(cells, opt);
    cells.forEach(e => LevelUtil.addLevelClass(e, e.attributes.z, ...this.levelPapers));
    return thisobj;
  }

  public removeCells(cells: joint.dia.Cell[], opt?: { [key: string]: any }): this {
    const thisobj = super.removeCells(cells, opt);
    cells.forEach(e => LevelUtil.removeLevelClass(e, ...this.levelPapers));
    return thisobj;
  }

  public removeLinks(cell: joint.dia.Cell, opt?: { [key: string]: any }): void {
    super.removeLinks(cell, opt);
    LevelUtil.removeLevelClass(cell, ...this.levelPapers);
  }

  public applyLevelClass() {
    this.getCells().forEach(cell => LevelUtil.setCurrentLevel(cell, ...this.levelPapers));
  }

// #endregion
}

export const LevelUtil = {
  setCurrentLevel(cell: joint.dia.Cell, ...papers: joint.dia.Paper[]): void {
    LevelUtil.changeLevel(cell, cell.attributes.z, ...papers);
  },
  changeLevel(cell: joint.dia.Cell, newLevel: number, ...papers: joint.dia.Paper[]): void {
    LevelUtil.removeLevelClass(cell, ...papers);
    cell.attributes.z = newLevel;
    LevelUtil.addLevelClass(cell, newLevel, ...papers);
  },
  addLevelClass(cell: joint.dia.Cell, level: number, ...papers: joint.dia.Paper[]) {
    papers.forEach(paper => {
      paper.findViewByModel(cell).$el.toggleClass('level-' + level, true);
    });
  },
  removeLevelClass(cell: joint.dia.Cell, ...papers: joint.dia.Paper[]) {
    papers.forEach(paper => {
      paper.findViewByModel(cell).$el.removeClass(
        (index, className) => (className.match(/(^|\s)level-\d+/g) || []).join(' '));
    });
  }
}