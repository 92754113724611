import Vue from 'vue';
import Router from 'vue-router';
import DiagramView from './pages/diagram-view/diagram-view';
import DiagramNotFound from './pages/error-page/diagram-not-found.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/diagrams/:diagramId(\\d+)',
      component: DiagramView,
    },
    {
        name: 'diagramNotFound',
        path: '/diagram-not-found',
        component: DiagramNotFound,
    },
    {
        name: 'notFound',
        path: '*',
        component: DiagramNotFound,
    },
  ]}
);

export default router;
