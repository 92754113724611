var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isToolbarOpened,
              expression: "isToolbarOpened"
            }
          ],
          staticClass: "position-relative",
          class: _vm.toolbarBgColor,
          attrs: { id: "toolbar" }
        },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("zoom-buttons", {
                staticClass: "mr-3",
                attrs: { color: _vm.color, paper: _vm.paper }
              }),
              _c("level-highlight", {
                staticClass: "mr-3",
                attrs: { color: _vm.color, paper: _vm.paper }
              }),
              _c(
                "b-button",
                {
                  staticClass: "ml-auto border-0",
                  attrs: {
                    variant: "outline-" + _vm.color,
                    id: "closeToolbarButton"
                  },
                  on: { click: _vm.closeToolbar }
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "icon",
                    attrs: { icon: ["fas", "angle-left"] }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "transition",
        { attrs: { name: "open-toolber-button" } },
        [
          !_vm.isToolbarOpened
            ? _c(
                "b-button",
                {
                  staticClass: "border-0",
                  attrs: {
                    variant: "outline-" + _vm.color,
                    id: "openToolbarButton"
                  },
                  on: { click: _vm.openToolbar }
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "icon",
                    attrs: { icon: ["fas", "angle-right"] }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }