import _ from 'underscore';
import { Component, Mixins, Vue, Prop, Emit } from 'vue-property-decorator';
import * as joint from 'jointjs';
import Color from 'color';
import anime from 'animejs';
import ZoomButtons from './zoom-buttons/zoom-buttons';
import LevelHighlight from './level-highlight/level-highlight';

@Component({
  components: {
    'zoom-buttons': ZoomButtons,
    'level-highlight': LevelHighlight,
  }
})
export default class ViewToolbars extends Vue {
  @Prop({required: true})
  private paper: joint.dia.Paper;
  @Prop({required: true})
  private bgColor: string;

  private isToolbarOpened: boolean = true;

  private get color(): string {
    if (new Color(this.bgColor).isLight()) {
      return 'dark';
    } else {
      return 'light';
    }
  }
  private get toolbarBgColor(): string {
    if (new Color(this.bgColor).isLight()) {
      return 'toolbar-bg-light';
    } else {
      return 'toolbar-bg-dark';
    }
  }

  private openToolbar() {
    this.isToolbarOpened = true;
    anime({
      targets: '#toolbar',
      width: '100%',
      'padding-right': '1rem',
      'padding-left': '1rem',
      easing: 'easeInOutQuad',
    });
    anime({
      targets: '#closeToolbarButton',
      opacity: '1',
      easing: 'easeInOutQuad',
    });
  }
  private closeToolbar() {
    anime({
      targets: '#toolbar',
      width: '0',
      'padding-right': '0',
      'padding-left': '0',
      easing: 'easeInOutQuad',
      complete: (anim) => {
        this.isToolbarOpened = false;
      }
    });
    anime({
      targets: '#closeToolbarButton',
      opacity: '0',
      easing: 'easeInOutQuad',
    });
  }
}
