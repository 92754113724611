import _ from 'underscore';
import * as joint from 'jointjs';

export default class MoveViewLib {
  public constructor(paper: joint.dia.Paper) {
    this.paper = paper;
    
    this.paper.setInteractivity(false);
    this.paper.el.addEventListener('pointerdown', (e) => {this.pointerStart(e)});
    this.paper.el.addEventListener('pointermove', (e) => {this.pointerMove(e)});
    this.paper.el.addEventListener('pointerup', (e) => {this.pointerEnd(e)});
    this.paper.el.addEventListener('wheel', (e) => {this.wheel(e)});
    this.paper.el.style.cursor = 'grab';
  }

  private paper: joint.dia.Paper;


  /** タッチ中のポインタ情報を、Pointer IDをキーとして保存したキャッシュ */
  private pointerCache: { [key: number]: joint.g.Point} = {};
  /** Pointer start時のスケール情報 */
  private baseScale: joint.Vectorizer.Scale | null = null;
  /** Pointer start時の位置情報 */
  private baseTranslate: joint.Vectorizer.Translation | null = null;
  /** タッチstart時のタッチ中心 */
  private baseTouchCenter: joint.g.Point | null = null;
  /** 2点タッチstart時のタッチ距離 */
  private baseTouchDistance: number | null = null;

  private wheel(evt: WheelEvent) {
    const scale = this.paper.scale().sx;
    if (evt.deltaY < 1) {
      this.paper.scale(scale * 1.1);
    } else {
      this.paper.scale(scale / 1.1);
    }
    evt.preventDefault();
  }

  private pointerStart(evt: PointerEvent) {
    this.paper.el.style.cursor = 'grabbing';
    // タッチ中の一覧キャッシュに保存
    this.pointerCache[evt.pointerId] = this.toJointPoint(evt);
    
    // タッチstart時の位置とスケールを保管
    this.baseTranslate = this.paper.translate();
    this.baseScale = this.paper.scale();
    
    const touchCount = this.touchCount();
    if (touchCount === 1) {
      // タッチが1点の場合は、start時のタッチの位置を記録しておく
      this.baseTouchCenter = this.toJointPoint(evt);

    } else if (touchCount === 2) {
      // タッチが2点の場合は、start時のタッチの中央位置と距離を記録しておく
      [this.baseTouchCenter, this.baseTouchDistance] = this.getCenterAndDistance();
    }
  }
  
  private pointerMove(evt: PointerEvent) {
    // タッチしていないときは処理しない
    if (!this.isTouched(evt)) return;
    
    // タッチ中の一覧キャッシュに保存
    this.pointerCache[evt.pointerId] = this.toJointPoint(evt);

    const touchCount = this.touchCount();
    if (touchCount === 1) {
      // タッチ数が1個の時は、タッチ位置を元に、画面位置を調整
      const currentPoint = this.toJointPoint(evt);
      const trans = currentPoint.difference(this.baseTouchCenter);

      this.paper.translate(
        Math.round(this.baseTranslate.tx + trans.x),
        Math.round(this.baseTranslate.ty + trans.y)
      );
    } else if (touchCount === 2) {
      // タッチ数が2個の時は、中央位置とタッチ幅を元に、画面位置と拡大率を調整
      let center: joint.g.Point;
      let distance: number;
      [center, distance] = this.getCenterAndDistance();

      const trans = center.difference(this.baseTouchCenter);
      const scale = (distance / this.baseTouchDistance) * this.baseScale.sx;
      this.paper.translate(
        Math.round(this.baseTranslate.tx + trans.x),
        Math.round(this.baseTranslate.ty + trans.y)
      ).scale(
        scale, scale
      );
    }
  }
  
  private pointerEnd(evt: PointerEvent) {
    delete this.pointerCache[evt.pointerId];

    if (this.touchCount() === 0) {
      // タッチ完全終了の処理
      this.baseScale = null;
      this.baseTouchCenter = null;
      this.baseTouchDistance = null;
      this.baseTranslate = null;
      this.paper.el.style.cursor = 'grab';
    }
  }

  private touchCount(): number {
    return Object.keys(this.pointerCache).length;
  }
  private isTouched(evt: PointerEvent): boolean {
    return (evt.pointerId in this.pointerCache);
  }

  private toJointPoint(evt: PointerEvent): joint.g.Point {
    return new joint.g.Point(evt.clientX, evt.clientY);
  }

  /**
   * 2点タッチ時の中央位置と距離を算出する。
   */
  private getCenterAndDistance(): [joint.g.Point, number] {
    const touches = Object.values(this.pointerCache);
    const baseTouchPosition: TwoTouchPosition = {
      p1: touches[0], p2: touches[1]
    }
    return [this.center(baseTouchPosition), this.distance(baseTouchPosition)];
  }

  private distance(pos: TwoTouchPosition): number {
    return pos.p1.distance(pos.p2);
  }
  private center(pos: TwoTouchPosition): joint.g.Point {
    return new joint.g.Point(
      (pos.p1.x + pos.p2.x) / 2,
      (pos.p1.y + pos.p2.y) / 2
    );
  }

}