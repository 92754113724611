import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class LoadingCover extends Vue {
  private isActive: boolean = false;
  @Prop()
  private message: string;

  mounted() {
    this.isActive = true;
  }

  public close(): void {
    this.isActive = false;
  }
}