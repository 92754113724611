import * as joint from 'jointjs';

const PADDING_X = 25;
const PADDING_Y = 50;

export function fitContents(paper: joint.dia.Paper) {
  paper.scaleContentToFit({
    padding: {
      top: PADDING_Y, bottom: PADDING_Y, 
      left: PADDING_X, right: PADDING_X
    },
    minScale: 0.1,
    maxScale: 3
  });
}
