import { util, routers } from 'jointjs';

var config = {

  // possible starting directions from an element
  startDirections: ['top', 'right', 'bottom', 'left', 'topLeft', 'bottomLeft', 'topRight', 'bottomRight'],

  // possible ending directions to an element
  endDirections: ['top', 'right', 'bottom', 'left', 'topLeft', 'bottomLeft', 'topRight', 'bottomRight'],

  // specify the directions used above and what they mean
  directionMap: {
    top: { x: 0, y: -1 },
    right: { x: 1, y: 0 },
    bottom: { x: 0, y: 1 },
    left: { x: -1, y: 0 },
    topLeft: { x: -1, y: -1 },
    bottomLeft: { x: -1, y: 1 },
    topRight: { x: 1, y: -1 },
    bottomRight: { x: 1, y: 1 },
  },
};

// public function
export const metroDiagonal = function (vertices, opt, linkView) {

  if (!util.isFunction(routers.metro)) {
    throw new Error('Metro-diagonal requires the metro router.');
  }

  return routers.metro(vertices, util.assign({}, config, opt), linkView);
};


// public function
export const metroHh = function (vertices, opt, linkView) {
  const config = {
    startDirections: ['right', 'left'],
    endDirections: ['right', 'left'],
  }
  if (!util.isFunction(routers.metro)) {
    throw new Error('Metro-diagonal requires the metro router.');
  }
  return routers.metroDiagonal(vertices, util.assign({}, config, opt), linkView);
};
// public function
export const metroVv = function (vertices, opt, linkView) {
  const config = {
    startDirections: ['top', 'bottom'],
    endDirections: ['top', 'bottom'],
  }
  if (!util.isFunction(routers.metro)) {
    throw new Error('Metro-diagonal requires the metro router.');
  }
  return routers.metroDiagonal(vertices, util.assign({}, config, opt), linkView);
};
// public function
export const metroHv = function (vertices, opt, linkView) {
  const config = {
    startDirections: ['top', 'bottom'],
    endDirections: ['right', 'left'],
  }
  if (!util.isFunction(routers.metro)) {
    throw new Error('Metro-diagonal requires the metro router.');
  }
  return routers.metroDiagonal(vertices, util.assign({}, config, opt), linkView);
};
// public function
export const metroVh = function (vertices, opt, linkView) {
  const config = {
    startDirections: ['right', 'left'],
    endDirections: ['top', 'bottom'],
  }
  if (!util.isFunction(routers.metro)) {
    throw new Error('Metro-diagonal requires the metro router.');
  }
  return routers.metroDiagonal(vertices, util.assign({}, config, opt), linkView);
};