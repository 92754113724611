import { routers, connectors, dia, shapes } from 'jointjs';
import { overpass } from './connector-overpass.js'
import { metroDiagonal, metroHh, metroHv, metroVh, metroVv } from './router-metro-directions.js'
import { rail } from './link-rail';

export function load() {
  (connectors as any).overpass = overpass;
  (routers as any).metroDiagonal = metroDiagonal;
  (routers as any).metroHh = metroHh;
  (routers as any).metroVv = metroVv;
  (routers as any).metroHv = metroHv;
  (routers as any).metroVh = metroVh;
  
  (shapes as any).raildiag = {};
  (shapes as any).raildiag.Rail = rail;
}