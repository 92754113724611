import Vue from 'vue';
import App from './App.vue';
import router from './router';
import '@babel/polyfill';
import {ButtonPlugin, FormCheckboxPlugin, FormInputPlugin} from 'bootstrap-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faMinus, faExclamationTriangle, faAngleLeft, faAngleRight, faObjectGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { LoadingCoverPlugin } from '@/components/parts/plugin/loading-cover/loading-cover-plugin'
import '@/load-adobe-fonts';
import marked from 'marked';
import DOMPurify from 'dompurify';

import * as JointJsCustomElementLoader from '@/lib/jointjs-defs/jointjs-custom-element-loader';

Vue.config.productionTip = false;
JointJsCustomElementLoader.load();
Vue.use(ButtonPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormInputPlugin);

Vue.use(LoadingCoverPlugin);

Vue.filter('markdown', value => {
  if (!value) return '';
  return DOMPurify.sanitize(marked(value, {breaks: true}));
})

Vue.component('font-awesome-icon', FontAwesomeIcon);
library.add( faPlus, faMinus, faExclamationTriangle, faAngleLeft, faAngleRight, faObjectGroup);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
