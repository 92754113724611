import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class ComponentBase extends Vue {
  protected get loadingMessage(): string {
    return '';
  }

  protected created() {
    this.setValues();
  }

  protected setValues() {
    const loading = this.$loading(this.loadingMessage);

    const promises = this.init();
    Promise.all(promises).finally(() => {
      this.$nextTick().then(() => {
        loading.close();
      })
    })
  }

  protected init(): Promise<any>[] {
    return [];
  }

  protected toast(title: string, message: string, variant: Variants) {
    const h = this.$createElement;
    this.$bvToast.toast(
      h('div', [
        h('p', {class: ['mb-2']}, message)
      ]),{
        title: title,
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 10000
      }
    );
  }
}

type Variants = 'default' | 'primary' | 'secondary' | 'danger' | 'warning' | 'success' | 'info'