var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isActive
      ? _c(
          "div",
          {
            staticClass:
              "loading-cover d-flex flex-column justify-content-center text-center align-items-center"
          },
          [
            _c("img", {
              staticClass: "text-center p-2",
              attrs: { src: require("@/assets/loading_grid.svg") }
            }),
            _vm.message
              ? _c("div", { staticClass: "message text-center p-2" }, [
                  _vm._v(_vm._s(_vm.message))
                ])
              : _vm._e()
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }