import { routers, connectors, dia } from 'jointjs';

export const MARK_NUM = 10;

// const arrowSize = {
//   x: 20,
//   y: 15
// };
// const circleSize = 15;

// const leftArrowMarker = {
//   d: `M 0,${-0.5 * arrowSize.y} v ${arrowSize.y} l ${-arrowSize.x},${-arrowSize.y * 0.5} Z`,
//   fill: 'currentColor',
//   stroke: 'none',
// }

// const rightArrowMarker = {
//   d: `M 0,${-0.5 * arrowSize.y} v ${arrowSize.y} l ${arrowSize.x},${-arrowSize.y * 0.5} Z`,
//   fill: 'currentColor',
//   stroke: 'none',
// }

// const stopMarker = {
//   d: `M 0,${-0.5 * arrowSize.y} v ${arrowSize.y}`,
//   stroke: 'currentColor',
//   fill: 'none',
// }

// const circleMarker = {
//   d: `M 0,0 a ${-0.5 * circleSize} ${-0.5 * circleSize} 0 1 1 ${circleSize} 0 a ${-0.5 * circleSize} ${-0.5 * circleSize} 0 1 1 ${-circleSize} 0`,
//   stroke: 'currentColor',
//   fill: 'none',
// }

export const defaultInstanceProperties = {
  attrs: {
    line: {
      connection: true,
      fill: 'none',
      strokeLinejoin: 'round',
      sourceMarker: {
        'type': 'none'
      },
      targetMarker: {
        'type': 'none'
      },
      connector: { name: 'overpass', args: {'jump': 'overpass'}}
    },
    // leftArrow0: leftArrowMarker,
    // leftArrow1: leftArrowMarker,
    // leftArrow2: leftArrowMarker,
    // leftArrow3: leftArrowMarker,
    // leftArrow4: leftArrowMarker,
    // rightArrow0: rightArrowMarker,
    // rightArrow1: rightArrowMarker,
    // rightArrow2: rightArrowMarker,
    // rightArrow3: rightArrowMarker,
    // rightArrow4: rightArrowMarker,
    // stop0: stopMarker,
    // stop1: stopMarker,
    // stop2: stopMarker,
    // stop3: stopMarker,
    // stop4: stopMarker,
    // circle0: circleMarker,
    // circle1: circleMarker,
    // circle2: circleMarker,
    // circle3: circleMarker,
    // circle4: circleMarker,
  }
}

export const prototypeProperties = {
  markup: [{
    tagName: 'path',
    selector: 'line'
  }]
}

export const rail = dia.Link.define(
  'raildiag.Rail', defaultInstanceProperties, prototypeProperties
);

export function changeAttributes(rail: joint.dia.Cell, color?: string, width?: number, type?: false | number[]) {
  if (color) {
    rail.attr('line/stroke', color);
  }
  if (width) {
    rail.attr('line/strokeWidth', width);
  }
  if (type) {
    rail.attr('line/strokeDasharray', type);
  }

  (rail.markup as dia.MarkupJSON).forEach(element => {
    if (element.selector) {
      if (
        ['stop'].some(test => element.selector.startsWith(test))
      ) {
        if (color) rail.attr(element.selector + '/' + 'stroke', color);
        if (width) rail.attr(element.selector + '/' + 'strokeWidth', width);
      } else if (
        ['leftArrow', 'rightArrow', 'circle'].some(test => element.selector.startsWith(test))
      ) {
        if (color) rail.attr(element.selector + '/' + 'fill', color);
      }
    }
  });
}
