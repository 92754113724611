var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c(
        "b-button",
        {
          staticClass: "mr-1",
          attrs: { variant: _vm.variant },
          on: { click: _vm.fit }
        },
        [
          _c("font-awesome-icon", {
            staticClass: "icon",
            attrs: { icon: ["fas", "object-group"] }
          })
        ],
        1
      ),
      _c(
        "b-button",
        {
          staticClass: "mr-1",
          attrs: { variant: _vm.variant },
          on: { click: _vm.expand }
        },
        [
          _c("font-awesome-icon", {
            staticClass: "icon",
            attrs: { icon: ["fas", "plus"] }
          })
        ],
        1
      ),
      _c(
        "b-button",
        { attrs: { variant: _vm.variant }, on: { click: _vm.reduce } },
        [
          _c("font-awesome-icon", {
            staticClass: "icon",
            attrs: { icon: ["fas", "minus"] }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }