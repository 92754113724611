// tslint:disable
/**
 * 配線略図エディタ(仮)-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: expresser@uxm.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Diagram
 */
export interface Diagram {
    /**
     * 配線略図データ
     * @type {string}
     * @memberof Diagram
     */
    data: string;
    /**
     * 配線略図サムネイルSVG
     * @type {string}
     * @memberof Diagram
     */
    svg: string;
    /**
     * 配線略図名
     * @type {string}
     * @memberof Diagram
     */
    name: string;
    /**
     * 公開フラグ
     * @type {boolean}
     * @memberof Diagram
     */
    isPublished: boolean;
    /**
     * 背景色
     * @type {number}
     * @memberof Diagram
     */
    backgroundColor: number;
    /**
     * 説明文
     * @type {string}
     * @memberof Diagram
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface DiagramAllOf
 */
export interface DiagramAllOf {
    /**
     * 説明文
     * @type {string}
     * @memberof DiagramAllOf
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface DiagramData
 */
export interface DiagramData {
    /**
     * 配線略図データ
     * @type {string}
     * @memberof DiagramData
     */
    data: string;
}
/**
 * 
 * @export
 * @interface DiagramDetail
 */
export interface DiagramDetail {
    /**
     * 配線略図ID 
     * @type {number}
     * @memberof DiagramDetail
     */
    id?: number;
    /**
     * 配線略図データ
     * @type {string}
     * @memberof DiagramDetail
     */
    data: string;
    /**
     * 配線略図サムネイルSVG
     * @type {string}
     * @memberof DiagramDetail
     */
    svg: string;
    /**
     * 配線略図名
     * @type {string}
     * @memberof DiagramDetail
     */
    name: string;
    /**
     * 公開フラグ
     * @type {boolean}
     * @memberof DiagramDetail
     */
    isPublished: boolean;
    /**
     * 背景色
     * @type {number}
     * @memberof DiagramDetail
     */
    backgroundColor: number;
    /**
     * 説明文
     * @type {string}
     * @memberof DiagramDetail
     */
    description?: string;
    /**
     * 登録日時
     * @type {Date}
     * @memberof DiagramDetail
     */
    createdAt?: Date;
    /**
     * 更新日時
     * @type {Date}
     * @memberof DiagramDetail
     */
    updatedAt?: Date;
    /**
     * 編集可能フラグ
     * @type {boolean}
     * @memberof DiagramDetail
     */
    isEditable: boolean;
}
/**
 * 
 * @export
 * @interface DiagramDetailAllOf
 */
export interface DiagramDetailAllOf {
    /**
     * 編集可能フラグ
     * @type {boolean}
     * @memberof DiagramDetailAllOf
     */
    isEditable: boolean;
}
/**
 * 
 * @export
 * @interface DiagramId
 */
export interface DiagramId {
    /**
     * 配線略図ID 
     * @type {number}
     * @memberof DiagramId
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface DiagramLimited
 */
export interface DiagramLimited {
    /**
     * 配線略図名
     * @type {string}
     * @memberof DiagramLimited
     */
    name: string;
    /**
     * 公開フラグ
     * @type {boolean}
     * @memberof DiagramLimited
     */
    isPublished: boolean;
    /**
     * 背景色
     * @type {number}
     * @memberof DiagramLimited
     */
    backgroundColor: number;
}
/**
 * 
 * @export
 * @interface DiagramList
 */
export interface DiagramList {
    /**
     * 
     * @type {Array<DiagramListElement>}
     * @memberof DiagramList
     */
    results?: Array<DiagramListElement>;
    /**
     * 現在のページ番号
     * @type {number}
     * @memberof DiagramList
     */
    currentPage?: number;
    /**
     * 最後のページ番号
     * @type {number}
     * @memberof DiagramList
     */
    lastPage?: number;
    /**
     * トータル件数
     * @type {number}
     * @memberof DiagramList
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface DiagramListElement
 */
export interface DiagramListElement {
    /**
     * 配線略図ID 
     * @type {number}
     * @memberof DiagramListElement
     */
    id?: number;
    /**
     * 配線略図名
     * @type {string}
     * @memberof DiagramListElement
     */
    name: string;
    /**
     * 公開フラグ
     * @type {boolean}
     * @memberof DiagramListElement
     */
    isPublished: boolean;
    /**
     * 背景色
     * @type {number}
     * @memberof DiagramListElement
     */
    backgroundColor: number;
    /**
     * 登録日時
     * @type {Date}
     * @memberof DiagramListElement
     */
    createdAt?: Date;
    /**
     * 更新日時
     * @type {Date}
     * @memberof DiagramListElement
     */
    updatedAt?: Date;
    /**
     * 配線略図サムネイルSVG
     * @type {string}
     * @memberof DiagramListElement
     */
    svg: string;
    /**
     * 作成者名
     * @type {string}
     * @memberof DiagramListElement
     */
    createrName: string;
    /**
     * 更新者名
     * @type {string}
     * @memberof DiagramListElement
     */
    updaterName: string;
    /**
     * 編集可能フラグ
     * @type {boolean}
     * @memberof DiagramListElement
     */
    isEditable: boolean;
}
/**
 * 
 * @export
 * @interface DiagramListElementAllOf
 */
export interface DiagramListElementAllOf {
    /**
     * 作成者名
     * @type {string}
     * @memberof DiagramListElementAllOf
     */
    createrName: string;
    /**
     * 更新者名
     * @type {string}
     * @memberof DiagramListElementAllOf
     */
    updaterName: string;
    /**
     * 編集可能フラグ
     * @type {boolean}
     * @memberof DiagramListElementAllOf
     */
    isEditable: boolean;
}
/**
 * 
 * @export
 * @interface DiagramSvg
 */
export interface DiagramSvg {
    /**
     * 配線略図サムネイルSVG
     * @type {string}
     * @memberof DiagramSvg
     */
    svg: string;
}
/**
 * 
 * @export
 * @interface Timestamp
 */
export interface Timestamp {
    /**
     * 登録日時
     * @type {Date}
     * @memberof Timestamp
     */
    createdAt?: Date;
    /**
     * 更新日時
     * @type {Date}
     * @memberof Timestamp
     */
    updatedAt?: Date;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * ニックネーム
     * @type {string}
     * @memberof User
     */
    nickname: string;
}

/**
 * DiagramApi - axios parameter creator
 * @export
 */
export const DiagramApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 配線略図削除
         * @param {number} diagramId 配線略図ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiagramsDiagramId(diagramId: number, options: any = {}): RequestArgs {
            // verify required parameter 'diagramId' is not null or undefined
            if (diagramId === null || diagramId === undefined) {
                throw new RequiredError('diagramId','Required parameter diagramId was null or undefined when calling deleteDiagramsDiagramId.');
            }
            const localVarPath = `/diagrams/{diagramId}`
                .replace(`{${"diagramId"}}`, encodeURIComponent(String(diagramId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication IDToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 配線略図一覧取得
         * @param {string} [name] 配線略図名（部分一致）
         * @param {number} [page] ページ番号
         * @param {number} [count] 1ページに出す結果数
         * @param {'desc' | 'asc'} [sort] ソート順
         * @param {'updated'} [order] 並び替え条件
         * @param {boolean} [isEditableOnly] 編集可能な配線略図のみを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiagrams(name?: string, page?: number, count?: number, sort?: 'desc' | 'asc', order?: 'updated', isEditableOnly?: boolean, options: any = {}): RequestArgs {
            const localVarPath = `/diagrams`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication IDToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (isEditableOnly !== undefined) {
                localVarQueryParameter['isEditableOnly'] = isEditableOnly;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 配線略図詳細取得
         * @param {number} diagramId 配線略図ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiagramsDiagramId(diagramId: number, options: any = {}): RequestArgs {
            // verify required parameter 'diagramId' is not null or undefined
            if (diagramId === null || diagramId === undefined) {
                throw new RequiredError('diagramId','Required parameter diagramId was null or undefined when calling getDiagramsDiagramId.');
            }
            const localVarPath = `/diagrams/{diagramId}`
                .replace(`{${"diagramId"}}`, encodeURIComponent(String(diagramId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication IDToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 配線略図登録
         * @param {Diagram} [diagram] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDiagrams(diagram?: Diagram, options: any = {}): RequestArgs {
            const localVarPath = `/diagrams`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication IDToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof diagram !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(diagram !== undefined ? diagram : {}) : (diagram || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 配線略図更新
         * @param {number} diagramId 配線略図ID
         * @param {Diagram} [diagram] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDiagramsDiagramId(diagramId: number, diagram?: Diagram, options: any = {}): RequestArgs {
            // verify required parameter 'diagramId' is not null or undefined
            if (diagramId === null || diagramId === undefined) {
                throw new RequiredError('diagramId','Required parameter diagramId was null or undefined when calling putDiagramsDiagramId.');
            }
            const localVarPath = `/diagrams/{diagramId}`
                .replace(`{${"diagramId"}}`, encodeURIComponent(String(diagramId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication IDToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof diagram !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(diagram !== undefined ? diagram : {}) : (diagram || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiagramApi - functional programming interface
 * @export
 */
export const DiagramApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 配線略図削除
         * @param {number} diagramId 配線略図ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiagramsDiagramId(diagramId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiagramDetail> {
            const localVarAxiosArgs = DiagramApiAxiosParamCreator(configuration).deleteDiagramsDiagramId(diagramId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 配線略図一覧取得
         * @param {string} [name] 配線略図名（部分一致）
         * @param {number} [page] ページ番号
         * @param {number} [count] 1ページに出す結果数
         * @param {'desc' | 'asc'} [sort] ソート順
         * @param {'updated'} [order] 並び替え条件
         * @param {boolean} [isEditableOnly] 編集可能な配線略図のみを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiagrams(name?: string, page?: number, count?: number, sort?: 'desc' | 'asc', order?: 'updated', isEditableOnly?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiagramList> {
            const localVarAxiosArgs = DiagramApiAxiosParamCreator(configuration).getDiagrams(name, page, count, sort, order, isEditableOnly, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 配線略図詳細取得
         * @param {number} diagramId 配線略図ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiagramsDiagramId(diagramId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiagramDetail> {
            const localVarAxiosArgs = DiagramApiAxiosParamCreator(configuration).getDiagramsDiagramId(diagramId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 配線略図登録
         * @param {Diagram} [diagram] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDiagrams(diagram?: Diagram, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = DiagramApiAxiosParamCreator(configuration).postDiagrams(diagram, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 配線略図更新
         * @param {number} diagramId 配線略図ID
         * @param {Diagram} [diagram] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDiagramsDiagramId(diagramId: number, diagram?: Diagram, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = DiagramApiAxiosParamCreator(configuration).putDiagramsDiagramId(diagramId, diagram, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DiagramApi - factory interface
 * @export
 */
export const DiagramApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 配線略図削除
         * @param {number} diagramId 配線略図ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiagramsDiagramId(diagramId: number, options?: any) {
            return DiagramApiFp(configuration).deleteDiagramsDiagramId(diagramId, options)(axios, basePath);
        },
        /**
         * 
         * @summary 配線略図一覧取得
         * @param {string} [name] 配線略図名（部分一致）
         * @param {number} [page] ページ番号
         * @param {number} [count] 1ページに出す結果数
         * @param {'desc' | 'asc'} [sort] ソート順
         * @param {'updated'} [order] 並び替え条件
         * @param {boolean} [isEditableOnly] 編集可能な配線略図のみを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiagrams(name?: string, page?: number, count?: number, sort?: 'desc' | 'asc', order?: 'updated', isEditableOnly?: boolean, options?: any) {
            return DiagramApiFp(configuration).getDiagrams(name, page, count, sort, order, isEditableOnly, options)(axios, basePath);
        },
        /**
         * 
         * @summary 配線略図詳細取得
         * @param {number} diagramId 配線略図ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiagramsDiagramId(diagramId: number, options?: any) {
            return DiagramApiFp(configuration).getDiagramsDiagramId(diagramId, options)(axios, basePath);
        },
        /**
         * 
         * @summary 配線略図登録
         * @param {Diagram} [diagram] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDiagrams(diagram?: Diagram, options?: any) {
            return DiagramApiFp(configuration).postDiagrams(diagram, options)(axios, basePath);
        },
        /**
         * 
         * @summary 配線略図更新
         * @param {number} diagramId 配線略図ID
         * @param {Diagram} [diagram] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDiagramsDiagramId(diagramId: number, diagram?: Diagram, options?: any) {
            return DiagramApiFp(configuration).putDiagramsDiagramId(diagramId, diagram, options)(axios, basePath);
        },
    };
};

/**
 * DiagramApi - object-oriented interface
 * @export
 * @class DiagramApi
 * @extends {BaseAPI}
 */
export class DiagramApi extends BaseAPI {
    /**
     * 
     * @summary 配線略図削除
     * @param {number} diagramId 配線略図ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagramApi
     */
    public deleteDiagramsDiagramId(diagramId: number, options?: any) {
        return DiagramApiFp(this.configuration).deleteDiagramsDiagramId(diagramId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 配線略図一覧取得
     * @param {string} [name] 配線略図名（部分一致）
     * @param {number} [page] ページ番号
     * @param {number} [count] 1ページに出す結果数
     * @param {'desc' | 'asc'} [sort] ソート順
     * @param {'updated'} [order] 並び替え条件
     * @param {boolean} [isEditableOnly] 編集可能な配線略図のみを返す
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagramApi
     */
    public getDiagrams(name?: string, page?: number, count?: number, sort?: 'desc' | 'asc', order?: 'updated', isEditableOnly?: boolean, options?: any) {
        return DiagramApiFp(this.configuration).getDiagrams(name, page, count, sort, order, isEditableOnly, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 配線略図詳細取得
     * @param {number} diagramId 配線略図ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagramApi
     */
    public getDiagramsDiagramId(diagramId: number, options?: any) {
        return DiagramApiFp(this.configuration).getDiagramsDiagramId(diagramId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 配線略図登録
     * @param {Diagram} [diagram] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagramApi
     */
    public postDiagrams(diagram?: Diagram, options?: any) {
        return DiagramApiFp(this.configuration).postDiagrams(diagram, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 配線略図更新
     * @param {number} diagramId 配線略図ID
     * @param {Diagram} [diagram] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagramApi
     */
    public putDiagramsDiagramId(diagramId: number, diagram?: Diagram, options?: any) {
        return DiagramApiFp(this.configuration).putDiagramsDiagramId(diagramId, diagram, options)(this.axios, this.basePath);
    }

}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ログイン中のユーザの情報を変更する。
         * @summary ユーザ情報変更
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUsersUserId(user?: User, options: any = {}): RequestArgs {
            const localVarPath = `/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication IDToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof user !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(user !== undefined ? user : {}) : (user || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * ログイン中のユーザの情報を変更する。
         * @summary ユーザ情報変更
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUsersUserId(user?: User, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).putUsersUserId(user, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * ログイン中のユーザの情報を変更する。
         * @summary ユーザ情報変更
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUsersUserId(user?: User, options?: any) {
            return UserApiFp(configuration).putUsersUserId(user, options)(axios, basePath);
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * ログイン中のユーザの情報を変更する。
     * @summary ユーザ情報変更
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putUsersUserId(user?: User, options?: any) {
        return UserApiFp(this.configuration).putUsersUserId(user, options)(this.axios, this.basePath);
    }

}


