var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100 h-100" }, [
    _c(
      "div",
      { ref: "editWrapper", staticClass: "edit-wrapper" },
      [
        _c("view-toolbars", {
          staticClass: "view-buttons w-100",
          attrs: { paper: _vm.paper, bgColor: _vm.bgColor }
        }),
        _c("div", {
          ref: "paper",
          staticClass: "edit",
          attrs: { id: "paper" }
        }),
        _c("div", { staticClass: "link" }, [
          _vm._v("Generated by "),
          _c("a", { attrs: { href: _vm.pageUrl, target: "_blank" } }, [
            _vm._v("配線略図エディタ")
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }