import _ from 'underscore';
import { Component, Mixins, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import ViewBase from '../../view-toolbars/viewbase';

@Component
export default class LevelHighlight extends Mixins(ViewBase) {
  private isEnabledEmphasis: boolean = false;
  private emphasizedLevel: number = 1;
  private styleElm = null;

  private get variant(): string {
    return 'outline-'+this.color;
  }

  private destroyed() {
    this.removeCssClass();
  }

  @Watch('isEnabledEmphasis')
  private onEnabledEmphasisCHanged() {
    if (this.isEnabledEmphasis) {
      this.addCssClass(`level-${this.emphasizedLevel}`);
    } else {
      this.removeCssClass();
    }
  }

  @Watch('emphasizedLevel')
  private onEmphasizedLevelChanged() {
    this.addCssClass(`level-${this.emphasizedLevel}`);
  }

  private removeCssClass() {
    if (this.styleElm) {
      const headElem = document.getElementsByTagName('head').item(0);
      headElem.removeChild(this.styleElm);
      this.styleElm = null;
    }
  }
  
  private addCssClass (className: string) {
    this.removeCssClass();

    const code = `
    g.joint-cell:not(.${className}) {
      opacity: .2;
    }`;

    this.styleElm = document.createElement('style');
    this.styleElm.innerHTML = code;
    this.styleElm.type = 'text/css'
    document.getElementsByTagName('head').item(0).appendChild(this.styleElm);
  };
}


