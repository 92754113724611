import { render, staticRenderFns } from "./diagram-view.html?vue&type=template&id=79c97abb&"
import script from "./diagram-view.ts?vue&type=script&lang=ts&"
export * from "./diagram-view.ts?vue&type=script&lang=ts&"
import style0 from "./diagram-view.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/EmbeddedClient/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('79c97abb')) {
      api.createRecord('79c97abb', component.options)
    } else {
      api.reload('79c97abb', component.options)
    }
    module.hot.accept("./diagram-view.html?vue&type=template&id=79c97abb&", function () {
      api.rerender('79c97abb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/diagram-view/diagram-view.vue"
export default component.exports