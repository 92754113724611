import _ from 'underscore';
import { Component, Mixins, Vue, Prop, Emit } from 'vue-property-decorator';
import * as joint from 'jointjs';
import ViewBase from '../viewbase';
import * as FitScaler from './fit-scaler';

@Component
export default class ZoomButtons extends Mixins(ViewBase) {
  @Prop({required: true})
  private paper: joint.dia.Paper;

  private get variant(): string {
    return 'outline-'+this.color;
  }

  private fit() {
    FitScaler.fitContents(this.paper);
  }
  private expand() {
    const scale = this.paper.scale().sx * 1.5
    this.paper.scale(scale, scale);
  }
  private reduce() {
    const scale = this.paper.scale().sx / 1.5
    this.paper.scale(scale, scale);
  }
}


