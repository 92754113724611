var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mx-sm-3 align-items-center text-nowrap d-flex",
      class: "text-" + _vm.color
    },
    [
      _c(
        "div",
        { staticClass: "d-flex mr-2" },
        [
          _c(
            "b-form-checkbox",
            {
              attrs: { name: "level-accent", switch: "" },
              model: {
                value: _vm.isEnabledEmphasis,
                callback: function($$v) {
                  _vm.isEnabledEmphasis = $$v
                },
                expression: "isEnabledEmphasis"
              }
            },
            [
              _c("span", { staticClass: "d-none d-sm-inline" }, [
                _vm._v("特定のレベルを強調")
              ]),
              _c("span", { staticClass: "d-inline d-sm-none" }, [
                _vm._v("強調表示")
              ])
            ]
          )
        ],
        1
      ),
      _vm.isEnabledEmphasis
        ? _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _c("label", { staticClass: "my-0 mx-2 label-level" }, [
                _c("span", { staticClass: "d-none d-sm-inline" }, [
                  _vm._v("レベル:")
                ]),
                _c("span", { staticClass: "d-inline d-sm-none" }, [
                  _vm._v("LV." + _vm._s(_vm.emphasizedLevel))
                ])
              ]),
              _c("b-form-input", {
                staticClass: "level-input d-none d-sm-inline",
                attrs: { type: "number" },
                model: {
                  value: _vm.emphasizedLevel,
                  callback: function($$v) {
                    _vm.emphasizedLevel = $$v
                  },
                  expression: "emphasizedLevel"
                }
              }),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "rounded-circle",
                      attrs: { variant: _vm.variant },
                      on: {
                        click: function() {
                          _vm.emphasizedLevel++
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "icon",
                        attrs: { icon: ["fas", "plus"] }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "rounded-circle",
                      attrs: { variant: _vm.variant },
                      on: {
                        click: function() {
                          _vm.emphasizedLevel--
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "icon",
                        attrs: { icon: ["fas", "minus"] }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }