





































import { Component, Vue } from "vue-property-decorator";

@Component
export default class DiagramNotFound extends Vue {
  private get pageUrl(): string {
    return process.env.VUE_APP_FRONTEND;
  }
}
