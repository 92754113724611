import { Component, Vue, Mixins, Watch } from 'vue-property-decorator';
import PageBase from '@/pages/page-base';
import * as joint from 'jointjs';
import _ from 'lodash';
import { DiagramApi } from '@/apis/def';
import { ungzip } from 'node-gzip';
import Color from 'color';
import ViewToolbars from './view-toolbars/view-toolbars';
import MoveViewLib from './move-view-lib';
import { ExtendedGraph } from './extended-graph';
import * as FitScaler from './view-toolbars/zoom-buttons/fit-scaler';

@Component({
  components: {
    'view-toolbars': ViewToolbars,
  },
})
export default class DiagramView extends Mixins(PageBase) {
  private bgColor = '#fafafa';
  private diagramInfo: {name: string, description: string, isPublished: boolean} = {
    name: '', description: '', isPublished: false
  };

  protected get loadingMessage() {
    return '配線略図をロードしています...';
  }
  private get diagramId(): number {
    return Number(this.$route.params.diagramId);
  }
  private get pageUrl(): string {
    return process.env.VUE_APP_FRONTEND + '/diagrams/' + this.diagramId;
  }

  private graph = new ExtendedGraph({}, { cellNamespace: joint.shapes });
  private paper = new joint.dia.Paper({});
  private moveViewLib: MoveViewLib;

  private mounted() {
    const rect = this.getPaperDimensions();
    this.paper = new joint.dia.Paper({
      el: this.$refs.paper,
      model: this.graph,
      gridSize: 10,
      width: rect.width,
      height: rect.height,
      background: {
        color: '#fafafa'
      },
      drawGrid: false,
      cellViewNamespace: joint.shapes,
    });
    this.graph.addLevelPaper(this.paper);
    window.addEventListener('resize', this.onWindowResized);
  }

  private destroyed() {
    window.removeEventListener('resize', this.onWindowResized);
  }

  /**
   * Paperの幅、高さ定義を取得する。
   */
  private getPaperDimensions(): DOMRect {
    return (this.$refs.editWrapper as HTMLElement).getBoundingClientRect();
  }

  private onWindowResized(evt: Event) {
    const rect = this.getPaperDimensions();
    this.paper.setDimensions(rect.width, rect.height);
  }

  protected pageInit(): Promise<any>[] {
    const promises: Promise<any>[] = [];
    promises.push(
      DiagramApi.getDiagramsDiagramId(this.diagramId).then(async res => {
        const buf = Buffer.from(res.data.data, 'base64');
        const unzip = await ungzip(buf);
        const graph = JSON.parse(unzip.toString('utf-8'))
        this.diagramInfo.name = res.data.name;
        this.diagramInfo.description = res.data.description ? res.data.description : '';
        this.diagramInfo.isPublished = res.data.isPublished;
        this.bgColor = Color(res.data.backgroundColor).hex();
        this.graph.fromJSON(graph);
        for (let cell of this.graph.getCells()) {
          if (cell.attributes.markup) {
            cell.markup = cell.attributes.markup;
          }
          cell.findView(this.paper).render();
        }
        this.paper.drawBackground({ color: this.bgColor });
        this.graph.applyLevelClass();
        this.paper.setInteractivity(false);

        this.moveViewLib = new MoveViewLib(this.paper);
        FitScaler.fitContents(this.paper);
      }).catch(err => {
        this.$router.push('/diagram-not-found');
      })
    );

    return promises;
  }
}
