import Vue, { PluginFunction, VueConstructor } from 'vue'
import LoadingCover from './loading-cover';

declare module 'vue/types/vue' {
  interface Vue {
    $loading: (message: string) => LoadingCover
  }
}

export class LoadingCoverPlugin {
  public static install: PluginFunction<any> = (Vue, options: any) => {
    Vue.prototype.$loading = (message: string) => {
      const vm = new LoadingCover({
        propsData: {message: message}
      }).$mount();
      document.body.appendChild(vm.$el);
      return vm;
    }
  }
}
